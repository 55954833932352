import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import slugify from 'slugify';
import { Portal } from 'react-portal';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { sortBy, first, isNull } from 'lodash';

import PageWrapper from 'components/page-wrapper';
import Box from 'components/box';
import Columns from 'components/columns';
import Divider from 'components/divider';
import Type from 'components/type';
import Head from 'components/head';
import DonationBox from 'components/donation-box';

import { Modal, ModalButton, Circle } from 'components/app-form/app-form.css';

const Donations = ({ data }) => {
  const {
    headline,
    beforeDonation,
    afterDonation,
    sponsorList,
    specialThanks,
  } = data.contentfulDonationPage;
  const product = first(data.allStripePrice.edges);

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams && urlParams.has('session_id')) {
        setShowModal(true);
        console.log(urlParams.get('session_id'));
        // setUrlParams(urlParams);
      }
    }
  }, []);

  return (
    <PageWrapper bg="bg-info select-infoBright" withAlert>
      <Head pageTitle="Donations" />
      <Box
        width="max-w-full pt-9 sm:pt-14 px-9 sm:px-14"
        flex="flex flex-col items-center"
      >
        <Box width="pt-14 px-6 md:px-16 max-w-xl xl:max-w-2xl">
          <Type align="text-center" size="large">
            {documentToReactComponents(headline.json)}
          </Type>
          <Divider />
        </Box>
        <Box width="px-6 md:px-16 pb-14 max-w-xl xl:max-w-2xl">
          <Columns>
            <div className="bi-avoid">
              <Type as="h3" align="text-left" size="large" spacing="mb-4">
                Supporting The Malee Scholarship
              </Type>
              <Type align="text-left" size="small" spacing="mb-10">
                {documentToReactComponents(beforeDonation.json)}
              </Type>
            </div>
            <div className="bi-avoid">
              <Type as="h3" align="text-left" size="large" spacing="mb-4">
                Give Today
              </Type>
              <Type align="text-left" size="small" spacing="mb-10">
                The Malee Scholarship is a registered 501(c)(3) non-profit
                organization which means your gifts are{' '}
                <b>100% tax deductible</b>.
              </Type>
              {/* <Link
            to="application-form"
            state={{ direction: 'toLeft' }}
            className="text-black no-underline mx-auto"
          >
            <div className="border border-solid border-black bg-infoBright hover:bg-info uppercase px-8 py-4">
              Start Application
            </div>
          </Link> */}
              <DonationBox product={product} />
            </div>
          </Columns>
          <Divider />
          <Columns spacing="mt-10 mb-10">
            <Type align="text-left" size="small" spacing="">
              {documentToReactComponents(afterDonation.json)}
            </Type>
          </Columns>
          
          <Divider />
          <Type as="h3" align="text-left" size="large" spacing="mb-4">
            Special thanks to
          </Type>
          <Type align="text-left" size="small" spacing="mb-10">
            We are grateful for the following sponsors who believe in the Malee
            mission and contribute to our community.
          </Type>
           <div className="col-count-1 md:col-count-2 col-gap-xl">
            {sponsorList.map(({ id, name, nameUrl, image, description }) => (
              <div key={id} className="bi-avoid">
                {image && (
                  <Img className="mb-6" key={image.id} fluid={image.fluid} />
                )}
                <Type align="text-left" size="small-serif" spacing="mb-2">
                  {name}
                </Type>
                <Type align="text-left" size="small" spacing="mb-10">
                  {documentToReactComponents(description.json)}
                </Type>
              </div>
            ))}
          </div>
          {/* <Type align="text-left" size="small" spacing="mb-10">
            We would like to express our special thanks to the following people
            for their donations:
          </Type>
          <Type as="ul" spacing="list-none ml-0 text-center md:col-count-2">
            {specialThanks.map(item => (
              <Type
                as="li"
                spacing="pl-0 mb-1"
                size="small-serif"
                key={slugify(item.id, { lower: true })}
              >
                {item.linkUrl ? (
                  <a
                    href={item.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:no-underline"
                  >
                    {item.text}
                  </a>
                ) : (
                  item.text
                )}
              </Type>
            ))}
          </Type> */}
        </Box>
      </Box>

      <Portal>
        <Modal visible={showModal}>
          <Circle className="bg-typeBright" style={{ fontSize: '2.5vw' }}>
            <p className="uppercase">Thank you for your support!</p>
            <p style={{ fontSize: '2rem' }}>
              Your generosity directly supports our annual Malee Scholars and
              allows us to carry out our mission to realize a more equitable and
              vibrant type design industry.
            </p>
            <ModalButton onClick={() => setShowModal(false)}>Close</ModalButton>
          </Circle>
        </Modal>
      </Portal>
    </PageWrapper>
  );
};

Donations.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Donations;

export const query = graphql`
  query DonationsQuery {
    allStripePrice {
      edges {
        node {
          id
          active
          product {
            id
            name
            images
          }
          unit_amount
        }
      }
    }

    contentfulDonationPage {
      id
      headline {
        json
      }
      beforeDonation {
        id
        json
      }
      afterDonation {
        id
        json
      }
      sponsorList {
        id
        name
        nameUrl
        description {
          id
          json
        }
        image {
          id
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
      specialThanks {
        id
        text
        linkUrl
      }
    }

    contentfulRecognitionPage {
      id
      headline {
        json
      }
      recipient {
        id
        name
        nameUrl
        role
        images {
          id
          fluid(maxWidth: 1280) {
            ...GatsbyContentfulFluid
          }
        }
        description {
          id
          json
        }
        optionalLeft {
          id
          json
        }
        optionalRight {
          id
          json
        }
      }
      finalistHeadline {
        json
      }
      finalists {
        id
        name
        nameUrl
        role
        images {
          id
          fluid(maxWidth: 1280) {
            ...GatsbyContentfulFluid
          }
        }
        description {
          id
          json
        }
        optionalLeft {
          id
          json
        }
        optionalRight {
          id
          json
        }
      }
      typographicExcellenceHeadline {
        json
      }
      excellenceLinks {
        id
        text
        linkUrl
      }
    }
  }
`;
