import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNull, isNumber, toNumber } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';

import Type from 'components/type';
import { Container } from './donation-box.css';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

const onClick = items => {
  fetch('/.netlify/functions/orderCreate', {
    method: 'POST',
    body: JSON.stringify(items),
  })
    .then(async response => {
      const { id } = await response.json();
      localStorage.setItem('cart', '{}');
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      alert(error.message);
    })
    .catch(err => alert(err.message));
};

const DonationBox = ({ product }) => {
  const amounts = [50, 100, 250, 300, 500, 'other'];
  const [amount, setAmount] = useState(null);
  const [otherAmount, setOtherAmount] = useState(26);

  return (
    <Container>
      <div className="flex flex-wrap">
        {amounts.map((i, k) => (
          <Type as="li" spacing="pl-0 w-1/3" size="small-serif" key={i}>
            <button
              className={` w-full ${(k === 0 || k === 3) && 'border-l'} ${k <
                3 && 'border-t'}
                ${i === amount ? 'bg-info' : 'bg-infoBright'}
                 border-r border-b border-solid border-black hover:bg-info uppercase px-8 py-4
              `}
              onClick={() => setAmount(i)}
            >
              {isNumber(i) && '$'}
              {i}
            </button>

            {/* {console.log(product)} */}
            {/* {item.linkUrl ? (
                <a
                  href={item.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:no-underline"
                >
                  {item.text}
                </a>
              ) : (
                item.text
              )} */}
          </Type>
        ))}
      </div>
      <br />
      <label htmlFor="other-amount">
        other:
        <input
          id="other-amount"
          className="bg-info border-t-0 border-l-0 border-r-0 border-b border-solid border-black uppercase px-2"
          type="number"
          min="10"
          step="1.00"
          max="999999"
          onChange={e => setOtherAmount(e.target.value)}
          value={otherAmount}
        />
      </label>
      <br />
      <button
        disabled={isNull(amount)}
        className={`border border-solid border-black text-black uppercase w-full mt-10 px-8 py-4
        ${isNull(amount) ? 'bg-info' : 'bg-infoBright hover:bg-info'}`}
        onClick={() =>
          onClick([
            {
              price_data: {
                currency: 'USD',
                product: product.node.product.id,
                unit_amount: isNumber(amount)
                  ? amount * 100
                  : otherAmount * 100,
              },
              // price: product.node.id,
              quantity: 1,
            },
          ])
        }
      >
        Give Today
        {amount && (isNumber(amount) ? `: $${amount}` : `: $${otherAmount}`)}
      </button>
    </Container>
  );
};

// DonationBox.propTypes = {
//   children: PropTypes.node.isRequired,
//   width: PropTypes.string,
//   flex: PropTypes.string,
//   span: PropTypes.string,
// };

export default DonationBox;
